import '../App.css';
import React, { Component } from 'react';
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK, { CoinbaseWalletProvider } from "@coinbase/wallet-sdk";
import logo from '../assets/logo.png';
import Story from '../Componets/story';
import Footer from '../Componets/footer';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import Roadmap from '../Componets/roadmap';
import $ from 'jquery';
import up from '../assets/flash.png';
import navbar from '../assets/navigation-bar.png';
import wrong from '../assets/wrong.png';
import Relic from '../Componets/relic';
import Tokenomics from '../Componets/tokenomics';
import nft1 from '../assets/NFTHouse9_frog_wizard_with_purple_fire_in_hands_plain_backgroun_624eb18e-b38f-4c93-a390-0022b24881bc-removebg-preview.png';
import nft2 from '../assets/zeus1.png';
import '../particle.css';
import Ticker from '../Componets/ticker';
import Carousel from '../Componets/carousel';
import discord from '../assets/discord.png';
import logoUp from '../assets/logo-up.png';

$(window).on('load', function () {
	setTimeout(function () { // allowing 3 secs to fade out loader
		$('.page-loader').fadeOut('slow');
	}, 3500);
});

function hideVideo() {

	if ($(window).width() > 768) {
		$("div.ticker-wrap").show();
		$("div.headers2").show();
	}

	if ($(window).width() <= 768) {
		$("div.ticker-wrap").show();
		$("div.rightMobileMain").show();
		$("div.connectAndSocial").show();
		$("div.headers2").show();

	}

}

var Scroll = require('react-scroll');

var Link = Scroll.Link;
var DirectLink = Scroll.DirectLink;
var Element = Scroll.Element;
var Events = Scroll.Events;
var scroll = Scroll.animateScroll;
var scrollSpy = Scroll.scrollSpy;

var imageNumber = 1;
var imageURL;

/*
$(".container").hover(
	function () {
		$(this).find(".text").show();
	},
	function () {
		$(this).find(".text").hide();
	}
);
*/

const opensea = () => {
	window.open("#");
}

const home = () => {
	window.open("#");
}

const mintSite = () => {
	/*window.open("/https://titantakeovermint.pages.dev/");*/
	window.open("https://mintfrogchain.pages.dev/");

}

const stakeSite = () => {
	//window.open("https://titantakeoverstake.pages.dev/");
	window.open("https://stake-frogchain.pages.dev/");

}

const gameSite = () => {
	//window.open("https://titantakeoverstake.pages.dev/");
	window.open("#");

}

const tweet = () => {
	window.open("#");
}

const discrd = () => {
	window.open("#");
}

$(function () {

	const stickyButton = $("#sticky-button");
	$(window).on("scroll", function () {
		if ($(this).scrollTop() > 200) {
			stickyButton.css("visibility", "visible");
		} else {
			stickyButton.css("visibility", "hidden");
		}
	});

	const scrollEffect = $('.scroll-effect');
	const scrollEffectContainer = $('.scroll-effect-container');

	const imageGif = $('.gifImg');

	$(window).on('scroll', function () {


		const containerTop = scrollEffect.offset().top;
		const containerBottom = containerTop + scrollEffect.outerHeight();


		const imageTop = imageGif.offset().top;
		const imageMiddle2 = imageTop + (imageGif.outerHeight() * 0.50);
		const imageMiddle3 = imageTop + (imageGif.outerHeight() * 0.75);
		const imageMiddle4 = imageTop + (imageGif.outerHeight() * 0.95);
		const imageBottom = imageTop + imageGif.outerHeight();

		console.log("imageTop : " + imageTop);
		console.log("imageMiddle2 : " + imageMiddle2);
		console.log("imageMiddle3 : " + imageMiddle3);
		console.log("imageMiddle4 : " + imageMiddle4);
		console.log("imageBottom : " + imageBottom);

		const windowTop = $(window).scrollTop();
		const windowBottom = windowTop + $(window).height();


		if (windowBottom < imageMiddle2) {
			imageNumber = 1;
			imageGif.addClass("gifImg");
			imageGif.removeClass("gifImg4");
			imageGif.removeClass("gifImg2");
			imageGif.removeClass("gifImg3");


		} else if (windowBottom > imageMiddle2 && windowBottom < imageMiddle3) {
			//this.setState({ _imageNo: 2 });
			imageNumber = 2;

			imageGif.removeClass("gifImg");
			imageGif.removeClass("gifImg4");
			imageGif.removeClass("gifImg3");
			imageGif.addClass("gifImg2");

		} else if (windowBottom > imageMiddle3 && windowBottom < imageMiddle4) {

			//this.setState({ _imageNo: 3 });
			imageNumber = 3;
			imageGif.removeClass("gifImg");
			imageGif.removeClass("gifImg4");
			imageGif.removeClass("gifImg2");
			imageGif.addClass("gifImg3");

		} else if (windowBottom > imageMiddle4) {
			//this.setState({ _imageNo: 4 });
			imageNumber = 4;
			imageGif.removeClass("gifImg");
			imageGif.removeClass("gifImg2");
			imageGif.removeClass("gifImg3");
			imageGif.addClass("gifImg4");

		} else {
			//this.setState({ _imageNo: 1 });
			imageNumber = 1;
			imageGif.removeClass("gifImg4");
			imageGif.addClass("gifImg");

		}


		if (windowBottom > containerTop) {
			scrollEffect.addClass('animate');
			scrollEffectContainer.addClass('animateC');

		} else {
			scrollEffect.removeClass('animate');
			scrollEffectContainer.removeClass('animateC');
		}

		console.log("containerTop :" + containerTop);
		console.log("containerBottom :" + containerBottom);
		console.log("windowTop :" + windowTop);
		console.log("windowBottom :" + windowBottom);

		console.log("imageNumber : " + imageNumber);

	});
});


let account;
let mintAmount = 1;
let valueOfNFTs = 0;
let totalSupplyNFT;
let onlyLeft;
let owner;
let publicSale;
let public_sale_cost;
let MAX_PER_WALLET;
let public_mint_status = false;
let wlCost;
let total_wl_limit;
let total_wl_count;
let wlMsg = "Whitelist Mint";
let FinalResult;

const ABI = [
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_initBaseURI",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_initNotRevealedUri",
				"type": "string"
			},
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"stateMutability": "nonpayable",
		"type": "constructor"
	},
	{
		"inputs": [],
		"name": "ApprovalCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApprovalToCurrentOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "ApproveToCaller",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "BalanceQueryForZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "MintZeroQuantity",
		"type": "error"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "OperatorNotAllowed",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "OwnerQueryForNonexistentToken",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TokenIndexOutOfBounds",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferCallerNotOwnerNorApproved",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferFromIncorrectOwner",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToNonERC721ReceiverImplementer",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "TransferToZeroAddress",
		"type": "error"
	},
	{
		"inputs": [],
		"name": "URIQueryForNonexistentToken",
		"type": "error"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "approved",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "ApprovalForAll",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "previousOwner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "OwnershipTransferred",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [],
		"name": "OPERATOR_FILTER_REGISTRY",
		"outputs": [
			{
				"internalType": "contract IOperatorFilterRegistry",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address[]",
				"name": "receiver",
				"type": "address[]"
			},
			{
				"internalType": "uint256[]",
				"name": "quantity",
				"type": "uint256[]"
			}
		],
		"name": "airdrop",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "contractURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"name": "email",
		"outputs": [
			{
				"internalType": "bytes",
				"name": "",
				"type": "bytes"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getApproved",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "getBaseURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			}
		],
		"name": "isApprovedForAll",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "max_per_wallet",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "quantity",
				"type": "uint256"
			}
		],
		"name": "mint",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "notRevealedUri",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "owner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "ownerOf",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"name": "publicMinted",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "publicSaleCost",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "public_mint_status",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "renounceOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "revealed",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_tokenId",
				"type": "uint256"
			},
			{
				"internalType": "uint256",
				"name": "_salePrice",
				"type": "uint256"
			}
		],
		"name": "royaltyInfo",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			},
			{
				"internalType": "bytes",
				"name": "data",
				"type": "bytes"
			}
		],
		"name": "safeTransferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "operator",
				"type": "address"
			},
			{
				"internalType": "bool",
				"name": "approved",
				"type": "bool"
			}
		],
		"name": "setApprovalForAll",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_newBaseURI",
				"type": "string"
			}
		],
		"name": "setBaseURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_contractURI",
				"type": "string"
			}
		],
		"name": "setContractURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes",
				"name": "_email",
				"type": "bytes"
			}
		],
		"name": "setEmail",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_MAX_SUPPLY",
				"type": "uint256"
			}
		],
		"name": "setMAX_SUPPLY",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_max_per_wallet",
				"type": "uint256"
			}
		],
		"name": "setMax_per_wallet",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "string",
				"name": "_notRevealedURI",
				"type": "string"
			}
		],
		"name": "setNotRevealedURI",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "_publicSaleCost",
				"type": "uint256"
			}
		],
		"name": "setPublicSaleCost",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "_receiver",
				"type": "address"
			},
			{
				"internalType": "uint96",
				"name": "_royaltyFeesInBips",
				"type": "uint96"
			}
		],
		"name": "setRoyaltyInfo",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "bytes4",
				"name": "interfaceId",
				"type": "bytes4"
			}
		],
		"name": "supportsInterface",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggleReveal",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "toggle_public_mint_status",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "index",
				"type": "uint256"
			}
		],
		"name": "tokenOfOwnerByIndex",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "tokenURI",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "newOwner",
				"type": "address"
			}
		],
		"name": "transferOwnership",
		"outputs": [],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "withdraw",
		"outputs": [],
		"stateMutability": "payable",
		"type": "function"
	}
];

const address = "0x413071c2a600437cb9B24493085385B57d541660";
let contract;

class Home extends Component {

	state = {
		walletAddress: "",
		totalSupply: "",
		nextPrice: "",
		nextSessionAmount: "",
		onlyLeftAmount: "",
		nftMintingAmount: '1',
		totalValue: "8000000000000000",
		maxmint: '',
		_public_sale_cost: '',
		_presalesale_cost: '',
		_MAX_PER_WALLET: '',
		_public_mint_status: '',
		_presale_status: '',
		_cost: '',
		_mintStatus: false,
		_wlMsg: 'Whitelist Mint',
		_FinalResult: '',
		_imageNo: 1,
		_navbarOpen: 0
	}

	onSubmit2 = async event => {
		event.preventDefault();

		console.log(this.state.walletAddress);

		try {
			let owner = await contract.methods.owner().call();


			if (account != owner) {


				try {

					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);

					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 1 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}
			} else {

				try {


					console.log("minAmount:" + mintAmount);
					console.log("valueOfNFTs:" + valueOfNFTs);


					this.setState({ statusError: false, statusLoading: true });
					await contract.methods.mint(mintAmount * 1).send({ gasLimit: 385000, from: account, value: this.state.totalValue * 0 });
					this.setState({ statusLoading: false, success: true });
					console.log("Mint Amount :" + this.state.mintAmount);

				} catch (err) {
					this.setState({ errorMassage: "ERROR : " + err.message, statusLoading: false, success: false, statusError: true });
					console.log(err);


				}






			}
		} catch (err) {

			console.log(err);

		}
	}

	walletConnect = async event => {
		event.preventDefault();

		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "811915bee3744bd38afcf17ecac0f9a6" // required
				}
			},
			coinbasewallet: {
				package: CoinbaseWalletSDK, // Required
				options: {
					appName: "Aterium Universe", // Required
					infuraId: "811915bee3744bd38afcf17ecac0f9a6", // Required
					rpc: "", // Optional if `infuraId` is provided; otherwise it's required
					chainId: 1, // Optional. It defaults to 1 if not provided
					darkMode: true // Optional. Use dark theme, defaults to false
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		const provider = await web3Modal.connect();

		//  Enable session (triggers QR Code modal)
		await provider.enable();

		const web3 = new Web3(provider);
		console.log("provider : " + provider);
		// Subscribe to accounts change
		provider.on("accountsChanged", (accounts) => {
			console.log(accounts);
		});

		// Subscribe to chainId change
		provider.on("chainChanged", (chainId) => {
			console.log(chainId);
		});

		// Subscribe to provider connection
		provider.on("connect", (info) => {
			console.log(info);
			console.log("I'm LOGGED IN");
		});

		// Subscribe to provider disconnection
		provider.on("disconnect", (error) => {
			console.log(error);
		});

		// test if wallet is connected
		if (web3Modal.cachedProvider) {
			// connected now you can get accounts
			console.log("web3Modal.cachedProvider :" + web3Modal.cachedProvider);
			console.log("provider :" + provider);

			const accounts = await web3.eth.getAccounts();

			account = accounts[0];
			this.setState({ walletAddress: account });

			contract = new web3.eth.Contract(ABI, address);
			console.log("contract :" + contract);

			if (provider) {


				//	(async () => {

				if (web3Modal.cachedProvider != "walletconnect" && web3Modal.cachedProvider != "coinbasewallet") {

					const chainId = 80001;

					if (window.ethereum.networkVersion !== chainId) {
						try {
							await window.ethereum.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}

				} else if (web3Modal.cachedProvider == "walletconnect") {

					const chainId = 80001;

					if (WalletConnectProvider.networkVersion !== chainId) {
						try {
							await WalletConnectProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await window.ethereum.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				} else if (web3Modal.cachedProvider == "coinbasewallet") {

					const chainId = 80001;

					if (CoinbaseWalletProvider.networkVersion !== chainId) {
						try {
							await CoinbaseWalletProvider.request({
								method: 'wallet_switchEthereumChain',
								params: [{ chainId: web3.utils.toHex(chainId) }],
							});
						} catch (err) {
							// This error code indicates that the chain has not been added to MetaMask.
							if (err.code === 4902) {
								await CoinbaseWalletProvider.request({
									method: 'wallet_addEthereumChain',
									params: [
										{
											/*chainName: 'Ethereum Mainnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Ethereum Mainnet', decimals: 18, symbol: 'ETH' },
											rpcUrls: ['https://mainnet.infura.io/v3/'],*/

											/*chainName: 'Smart Chain - Testnethttps://testnet.bscscan.com',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Smart Chain', decimals: 18, symbol: 'BNB' },
											rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],*/

											chainName: 'Mumbai Testnet',
											chainId: web3.utils.toHex(chainId),
											nativeCurrency: { name: 'Mumbai Testnet', decimals: 18, symbol: 'MATIC' },
											rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
										},
									],
								});
							}
						}
					}


					try {

						totalSupplyNFT = await contract.methods.totalSupply().call();
						this.setState({ totalSupply: totalSupplyNFT });
						console.log("Total Supply:" + totalSupplyNFT);

						publicSale = await contract.methods.balanceOf(account).call();
						this.setState({ myNFTWallet: publicSale });

						public_sale_cost = await contract.methods.publicSaleCost().call();
						this.setState({ _public_sale_cost: public_sale_cost });
						console.log("public_sale_cost :" + public_sale_cost);
						this.setState({ _cost: public_sale_cost });
						this.setState({ _mintStatus: false });

						MAX_PER_WALLET = await contract.methods.max_per_wallet().call();
						this.setState({ _MAX_PER_WALLET: MAX_PER_WALLET });
						console.log("MAX_PER_WALLET :" + MAX_PER_WALLET);

						public_mint_status = await contract.methods.public_mint_status().call();
						this.setState({ _public_mint_status: public_mint_status });
						console.log("public_mint_status :" + public_mint_status);

						owner = await contract.methods.owner().call();
						console.log("Owner" + owner);
						//..........................................................................//
						if (owner == account) {
							console.log("owner : " + owner)
							onlyLeft = 10000 - totalSupplyNFT;

							if (mintAmount > onlyLeft) {
								mintAmount = onlyLeft;
							}

							valueOfNFTs = mintAmount * 0;



							this.setState({ nftMintingAmount: mintAmount });

							this.setState({ totalValue: valueOfNFTs });
						} else {
							mintAmount = 1;

							if (totalSupplyNFT == 10000) {

								onlyLeft = 10000 - totalSupplyNFT;
								mintAmount = onlyLeft;
								this.setState({ msg: "SOLD OUT" });

							} else {
								mintAmount = 1;
								onlyLeft = MAX_PER_WALLET - publicSale;

								if (mintAmount > onlyLeft) {
									mintAmount = onlyLeft;
								}
								//mintAmount = onlyLeft;

								valueOfNFTs = mintAmount * this.state._cost;



								this.setState({ nftMintingAmount: mintAmount });

								this.setState({ totalValue: valueOfNFTs });
							}
						}
						///................................///



						//..........................................................................//

					} catch (err) {
						console.log("err: " + err);

					}


				}


				//})();

				//.....................................................................//

				// Legacy providers may only have ethereum.sendAsync
				const chainId = await provider.request({
					method: 'eth_chainId'
				})

			} else {

				// if the provider is not detected, detectEthereumProvider resolves to null
				console.error('Please install a Valid Wallet');
				alert('A valid provider could not be found!');

			}

		}

	}

	walletDisconnect = async event => {
		event.preventDefault();


		const providerOptions = {
			walletconnect: {
				package: WalletConnectProvider, // required
				options: {
					infuraId: "bf933c3446b3464c988114813a1360ac" // required
				}
			}
		};

		const web3Modal = new Web3Modal({
			network: "mainnet", // optional
			cacheProvider: true, // optional
			providerOptions // required
		});



		// disconnect wallet
		web3Modal.clearCachedProvider();
		window.location.reload();

	}

	closeNav = () => {
		const navBar = document.querySelector(".littleNav");
		navBar.classList.add("hidden");
	}

	closeNav = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 0 });
	}

	navbarOpen = async event => {
		event.preventDefault();

		this.setState({ _navbarOpen: 1 });
	}

	render() {

		return (

			<div class="allWrap">

				<div class="light">

					<div class="page-loader">
						<div class="containerLoad">
							<div class="ring">
								<div class="h1Load">Loading...</div>
							</div>
						</div>
					</div>

					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>
					<div class="circle-container2">
						<div class="circle"></div>
					</div>

					<div class="cont">

						<Element name="up">

							{this.state._navbarOpen < 1 ?
								(

									<div class="miniBarMain">
										<div class="title"><img class="logoUp" src={logoUp} />FROG CHAIN</div>
										<div class="connect2">
											{this.state.walletAddress === '' ?
												(<form class="connect2-2">
													<button class="button-92" onClick={mintSite}>
														Mint Area
													</button>
												</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
													<button class="button-92">
														{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

													</button>
												</form>)}

										</div>
										<div class="navbarMainImgSet"><img class="navbar" onClick={this.navbarOpen} src={navbar} /></div>
									</div>) :
								(<div class="miniBarMain">
									<div class="title"><img class="logoUp" src={logoUp} />FROG CHAIN</div>
									<div class="connect2">
										{this.state.walletAddress === '' ?
											(<form onSubmit={this.walletConnect} class="connect2-2">
												<button class="button-92">
													Connect
												</button>
											</form>) : (<form class="connect2-2" onSubmit={this.walletDisconnect}>
												<button class="button-92">
													{this.state.walletAddress.slice(0, 3) + "..." + this.state.walletAddress.slice(39, 42)}

												</button>
											</form>)}

									</div>
									<div class="navbarMainImgSet"><img class="navbar" onClick={this.closeNav} src={wrong} /></div>
								</div>)}

							<div class="headers">

								<div class="h1">
									<div class="title"><img class="logoUp" src={logoUp} />FROG CHAIN</div>

									<div class="connect">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550} onClick={home}><span class="onPage">Home</span></Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}>Mint</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550} onClick={gameSite}>Battlefield</Link></div>
									</div>

									<div class="right">

										<div class="icons">
											<img onClick={opensea} src={os} />
											<img onClick={tweet} src={twitter} />
											<img onClick={discrd} src={discord} />
										</div>
										<div class="connect2">
											<form class="connect2-2">
												<button class="button-92" onClick={mintSite}>
													Mint
												</button>
											</form>

										</div>

									</div>


									<div class="connectMob">
										<div ><Link activeClass="" id="fontSize" to="story" spy={true} smooth={true} duration={550}>ABOUT</Link></div>
										<div ><Link activeClass="" id="fontSize" to="rm" spy={true} smooth={true} duration={550}>ROADMAP</Link></div>
										<div ><Link activeClass="" id="fontSize" to="mint" spy={true} smooth={true} duration={550}>MINT</Link></div>
									</div>



								</div>
							</div>

							{this.state._navbarOpen > 0 ?
								(<div class="littleNav" data-aos="fade-left">

									<div ><Link activeClass="" id="fontSize2" to="story" spy={true} smooth={true} duration={550} onClick={home}><span class="onPage">Home</span></Link></div>
									<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={mintSite}>Mint</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={stakeSite}>Staking</Link></div>
									<div ><Link activeClass="" id="fontSize2" to="mint" spy={true} smooth={true} duration={550} onClick={gameSite}>Battlefield</Link></div>


									<div class="icons">
										<img onClick={opensea} src={os} />
										<img onClick={tweet} src={twitter} />
										<img onClick={discrd} src={discord} />
									</div>
								</div>) : null}
						</Element>
						<div class="introduction">

							<div class="in2">
								<img src={logo} />
							</div>
							<div class="images2"><img src={nft2} data-aos="fade-right" data-aos-duration="1500" /> <img data-aos="fade-left" data-aos-duration="1500" src={nft1} /> </div>
							<Ticker />

						</div>

					</div>
					<Element name="story">
						<Story />

					</Element>

					<div class="gifMain"><div class="gifImg"></div></div>

					<Roadmap />

					<Element name="relic">
						<Relic />
					</Element>

					<Tokenomics />

					<Carousel />

					<Footer />

				</div>

			</div >)
	}
}

export default Home;