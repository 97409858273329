import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import potion from '../assets/profilebg.21e2216ddbd680cc64dc.png';
import fire from '../assets/fire.png';
import grow from '../assets/diagram.png';
import community from '../assets/diversity.png';
import coins from '../assets/tokenization.png';
import battle from '../assets/sword.png';
import scroll from '../assets/logo-up.png';

const Stry = () => {
    useEffect(()=> {
        Aos.init({ duration: 4000 }); 
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">
                <div class="boxWrap2Sub1">
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>
                    <div class="firefly"></div>

                    <div class="about">

                    <img class="zeus" src={potion} data-aos="fade-left" data-aos-duration="1000" />

                        <div class="aboutPS">

                            <div class="storyConAndImg"><div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">Deflationary<br /> <span class="bigLetters">Token Supply</span></div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={grow} /></div></div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">Burns on<br /> <span class="bigLetters">Every Transaction</span></div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={fire} /></div>
                            </div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">High Value on<br /> <span class="bigLetters">Community involvement </span></div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={community} /></div>
                            </div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000">Earn Tokens from<br /> <span class="bigLetters">Every Transaction Just by Holding FROG</span> </div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={coins} /></div>
                            </div>

                            <div class="storyConAndImg">
                                <div class="aboutPDiv" data-aos="fade-right" data-aos-duration="1000"><span class="bigLetters" >Join Battles</span> <br />and Win Tokens</div>
                                <div class="ironfist" data-aos="fade-up" data-aos-duration="1000"><img src={battle} /></div>
                            </div>
                        </div>

                    </div>
                    <div class="line2">
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                    </div>

                </div>

            </div>
        )
    }
}

export default Story;

