import React, { Component } from 'react';
import '../App.css';
import $ from "jquery";
import scroll from '../assets/logo-up.png';
import timer from '../assets/timer.png';
import coins from '../assets/moreCoins.png';
 
$(function () {
    const scrollEffect = $('.scroll-effect-rm');

    $(window).on('scroll', function () {
        const containerTop = scrollEffect.offset().top;
        const containerBottom = containerTop + scrollEffect.outerHeight();
        const windowTop = $(window).scrollTop();
        const windowBottom = windowTop + $(window).height();

        if (windowBottom > containerTop) {
            scrollEffect.addClass('animate');
        } else {
            scrollEffect.removeClass('animate');
        }


        console.log("containerTop :" + containerTop);
        console.log("containerBottom :" + containerBottom);
        console.log("windowTop :" + windowTop);
        console.log("windowBottom :" + windowBottom);


    });
});


class Roadmap extends Component {


    render() {
        return (

            <div class="rmbg">
                <div class="rmbg2">
                    <div class="scroll-effect-container">
                        <div class="scroll-effect-rm"><span class="storyH2">ROADMAP</span></div>
                    </div>
                    <img class="ball" src={timer} />

                    <div class="rmConMain">

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE 1</div>
                            <div class="rmbox2">
                                <div class="rmConNew">Presale Launch</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">Marketing</div>
                            </div>
                            <div class="rmbox1-3">PHASE 2</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE 3</div>
                            <div class="rmbox2">
                                <div class="rmConNew">CEX Listing</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">4K TG Members</div>
                            </div>
                            <div class="rmbox1-3">PHASE 4</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE 5</div>
                            <div class="rmbox2">
                                <div class="rmConNew">Stake Platform</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">COMING SOON</div>
                            </div>
                            <div class="rmbox1-3">PHASE 6</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE 7</div>
                            <div class="rmbox2">
                                <div class="rmConNew">COMING SOON</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">COMING SOON</div>
                            </div>
                            <div class="rmbox1-3">PHASE 8</div>

                        </div>
                    </div>

                    <div class="rmConMainMob">

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE <br/> 1</div>
                            <div class="rmbox2">
                                <div class="rmConNew">Presale Launch</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">Marketing</div>
                            </div>
                            <div class="rmbox1-3">PHASE <br/> 2</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE <br/> 3</div>
                            <div class="rmbox2">
                                <div class="rmConNew">CEX Listing</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">4K TG Members</div>
                            </div>
                            <div class="rmbox1-3">PHASE <br/> 4</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE <br/> 5</div>
                            <div class="rmbox2">
                                <div class="rmConNew">Stake Platform</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">COMING SOON</div>
                            </div>
                            <div class="rmbox1-3">PHASE <br/> 6</div>

                        </div>

                        <div class="rm2Box" data-aos="fade-right" data-aos-duration="1000">
                            <div class="rmbox1">PHASE <br/> 7</div>
                            <div class="rmbox2">
                                <div class="rmConNew">COMING SOON</div>
                            </div>
                        </div>

                        <div class="rm3Box" data-aos="fade-left" data-aos-duration="1000">

                            <div class="rmbox2-3">
                                <div class="rmConNew">COMING SOON</div>
                            </div>
                            <div class="rmbox1-3">PHASE <br/> 8</div>

                        </div>
                    </div>

                    <div class="line">
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                        <img src={scroll} />
                    </div>

                </div>
                <img class="bawl" src={coins} />

            </div>


        );

    }
}

export default Roadmap;