import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import $ from "jquery";
import coin from '../assets/NFTHouse9_frog_face_embed_in_the_gold_coin_c5f3b6c3-9461-4548-968b-0c6401cf4a75-removebg-preview.png';

$(function() {
    const scrollEffect = $('.scroll-effect-tokenomics');
  
    $(window).on('scroll', function() {
      const containerTop = scrollEffect.offset().top;
      const containerBottom = containerTop + scrollEffect.outerHeight();
      const windowTop = $(window).scrollTop();
      const windowBottom = windowTop + $(window).height();
  
      if (windowBottom > containerTop) {
       scrollEffect.addClass('animate');
      } else {
       scrollEffect.removeClass('animate');
      }


      console.log("containerTop :" + containerTop);
      console.log("containerBottom :" + containerBottom);
      console.log("windowTop :" + windowTop);
      console.log("windowBottom :" + windowBottom);


    });
  });
  
const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Tokenomics extends Component {

    render() {
        return (

            <div class="boxWrap2StoryT">
                <div class="boxWrap2Sub">
                 <div class="scroll-effect-container">
                        <div class="scroll-effect-tokenomics"><span class="storyH2">TOKENOMICS</span></div>
                    </div>
                    <div class="about">                        
                    <div class="aboutP">

                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Total Supply – <span class="hl">1,000,000,000</span></div>
                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Burned Amount – <span class="hl">83%</span></div>
                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Token Address – <span class="hl">0xDCD1...85D2C5</span></div>
                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Market Cap – <span class="hl">$0</span></div>
                        <div class="aboutPDiv2" data-aos="fade-right" data-aos-duration="1000">Price – <span class="hl">$0.00196056 ( -9.152%)</span></div>
  
                        </div>

                        <img class="zeus2" src={coin} />

                    </div>
                </div>
            </div>
        )
    }
}

export default Tokenomics;

