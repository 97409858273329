import React, { Component } from 'react';
import '../carousel.css';
import d1 from '../assets/pepe1.png';
import d2 from '../assets/pepe2.png';
import d3 from '../assets/pepe3.png';
import d4 from '../assets/pepe4.png';
import d5 from '../assets/pepe1.png';
import d6 from '../assets/pepe2.png';
import d7 from '../assets/pepe3.png';
import d8 from '../assets/pepe4.png';
import d9 from '../assets/pepe1.png';
import d10 from '../assets/pepe2.png';
import d11 from '../assets/pepe3.png';

class Carousel extends Component {

    render() {
        return (

            <div class="boxWrapC">
               
                <div class="slider">
                    <div class="slide-track2">
                        <div class="slide">
                            <img class="carouselIMG" src={d1} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d2} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d3} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d4} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d5} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d6} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d7} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d8} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d9} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d10} alt="d5" />
                        </div>
                        <div class="slide">
                            <img class="carouselIMG" src={d11} alt="d5" />
                        </div>
                 
                    </div>
                </div>
 

            </div>
        );
    }
}

export default Carousel;        